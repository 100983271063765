import { FormEvent, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import clsx from "clsx"
import Image from "next/legacy/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { Navbar } from "react-bootstrap"
import { useToggle } from "react-use"
import ActiveLink from "./active-link"
import S from "./header.module.scss"
import useUser from "../lib/useUser"
import CartIcon from "../svgs/cart.svg"
import Logo from "../svgs/logo.svg"
import SearchIcon from "../svgs/search.svg"
import API from "@/lib/api"
import { getProductUrl } from "@/lib/product"
import { CartResponse, ProductsPaginated } from "@/types"

const Header = () => {
  const { user } = useUser()
  const [openedMobileMenu, toggleMobileMenu] = useToggle(false)
  const router = useRouter()
  const searchQuery = router.query.q
  const [currentSearch, setCurrentSearch] = useState("")
  const [selected, setSelected] = useState(0)
  const [isSearching, setIsSearching] = useState(false)

  const { data } = useQuery<CartResponse, Error>({
    queryKey: ["cart"],
    queryFn: () => API.services.cart.get(user!.access_token),
    enabled: !!user && !!user.access_token,
  })

  const { data: searchAutocomplete, isLoading: searchAutocompleteLoading } =
    useQuery<ProductsPaginated, Error>({
      queryKey: ["search", currentSearch, 1],
      queryFn: () => API.services.products.search(currentSearch),
      enabled: !!currentSearch && currentSearch.length >= 2,
    })

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    // User pressed the enter key
    if (e.keyCode === 13 && searchAutocomplete && selected !== 0) {
      // setSelected(0)
      e.preventDefault()
      await router.push(getProductUrl(searchAutocomplete.data[selected - 1]))
    }

    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (selected === 0) {
        return
      }

      setSelected((newValue) => newValue - 1)
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (selected - 1 === searchAutocomplete?.data.length) {
        return
      }

      setSelected((newValue) => newValue + 1)
    }
  }

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const input = e.currentTarget.elements[0] as HTMLInputElement

    setIsSearching(false)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({
      pathname: "/search",
      query: { q: input.value },
    })
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const Actions = ({ className }: { className: string }) => (
    <div className={className}>
      {user?.isLoggedIn && (
        <Link
          href="/my-account"
          className="btn btn-primary mr-3 mr-md-0 mx-md-4"
        >
          My Account
        </Link>
      )}

      {!user?.isLoggedIn && (
        <Link href="/login" className="btn btn-primary mr-3 mr-md-0 mx-md-4">
          Log in
        </Link>
      )}

      <Link
        href="/cart"
        className={clsx(S.cartIcon, {
          [S.active]: data && data.items.length > 0,
        })}
      >
        <CartIcon />
      </Link>
    </div>
  )

  return (
    <Navbar bg="light" expand="xl" className={clsx(S.navbar, "bg-white")}>
      <div className="container">
        <Link href="/" className="navbar-brand mr-2 mr-md-5">
          <Logo />
        </Link>

        <Actions className="d-flex align-items-center flex-nowrap d-xl-none ml-auto mr-4" />

        <Navbar.Toggle
          className={clsx(
            "navbar-toggler hamburger hamburger--squeeze",
            `${openedMobileMenu ? "is-active" : ""}`,
          )}
          type="button"
          aria-expanded={openedMobileMenu}
          aria-label="Toggle navigation"
          onClick={toggleMobileMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Navbar.Toggle>

        <Navbar.Collapse>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <ActiveLink href="/fabrics" activeClassName="active">
                <a className="nav-link">Fabrics</a>
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink href="/components" activeClassName="active">
                <a className="nav-link">Components</a>
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink href="/tooling" activeClassName="active">
                <a className="nav-link">Tooling</a>
              </ActiveLink>
            </li>
            <li className="nav-item">
              <ActiveLink href="/point-of-sale" activeClassName="active">
                <a className="nav-link">Point of Sale</a>
              </ActiveLink>
            </li>
          </ul>
          <form
            className={clsx(
              S.searchForm,
              "form-inline mt-3 mb-4 my-xl-2 my-lg-0",
            )}
            onSubmit={(e) => handleSearchSubmit(e)}
          >
            <input
              className="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
              defaultValue={searchQuery}
              onChange={(event) => {
                setCurrentSearch(event.target.value)
                setIsSearching(true)
              }}
              onKeyDown={(event) => handleKeyDown(event)}
              onFocus={() => setIsSearching(true)}
              onBlur={() => setTimeout(() => setIsSearching(false), 150)}
              required
            />
            <button type="submit" className="btn" aria-label="Search">
              <SearchIcon />
            </button>

            {!searchAutocompleteLoading &&
              searchAutocomplete &&
              searchAutocomplete.data?.length > 0 &&
              currentSearch &&
              isSearching && (
                <ul className={S.searchAutocomplete}>
                  {searchAutocomplete.data.map((item, index) => (
                    <li key={item.id}>
                      <Link
                        href={getProductUrl(item)}
                        className={clsx({
                          [S.selected]: index + 1 === selected,
                        })}
                      >
                        <span>{item.product_title}</span>
                        <Image
                          src={
                            item.image_url ||
                            "/images/placeholder-image-box.svg"
                          }
                          width={32}
                          height={32}
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
          </form>

          <Actions className="d-none d-xl-flex align-items-center" />
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Header
