const iso3166Codes = [
  { name: "Afghanistan", alpha3: "AFG", code: "004" },
  { name: "Åland Islands", alpha3: "ALA", code: "248" },
  { name: "Albania", alpha3: "ALB", code: "008" },
  { name: "Algeria", alpha3: "DZA", code: "012" },
  { name: "American Samoa", alpha3: "ASM", code: "016" },
  { name: "Andorra", alpha3: "AND", code: "020" },
  { name: "Angola", alpha3: "AGO", code: "024" },
  { name: "Anguilla", alpha3: "AIA", code: "660" },
  { name: "Antarctica", alpha3: "ATA", code: "010" },
  { name: "Antigua and Barbuda", alpha3: "ATG", code: "028" },
  { name: "Argentina", alpha3: "ARG", code: "032" },
  { name: "Armenia", alpha3: "ARM", code: "051" },
  { name: "Aruba", alpha3: "ABW", code: "533" },
  { name: "Australia", alpha3: "AUS", code: "036" },
  { name: "Austria", alpha3: "AUT", code: "040" },
  { name: "Azerbaijan", alpha3: "AZE", code: "031" },
  { name: "Bahamas", alpha3: "BHS", code: "044" },
  { name: "Bahrain", alpha3: "BHR", code: "048" },
  { name: "Bangladesh", alpha3: "BGD", code: "050" },
  { name: "Barbados", alpha3: "BRB", code: "052" },
  { name: "Belarus", alpha3: "BLR", code: "112" },
  { name: "Belgium", alpha3: "BEL", code: "056" },
  { name: "Belize", alpha3: "BLZ", code: "084" },
  { name: "Benin", alpha3: "BEN", code: "204" },
  { name: "Bermuda", alpha3: "BMU", code: "060" },
  { name: "Bhutan", alpha3: "BTN", code: "064" },
  {
    name: "Bolivia (Plurinational State of)",
    alpha3: "BOL",
    code: "068",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha3: "BES",
    code: "535",
  },
  { name: "Bosnia and Herzegovina", alpha3: "BIH", code: "070" },
  { name: "Botswana", alpha3: "BWA", code: "072" },
  { name: "Bouvet Island", alpha3: "BVT", code: "074" },
  { name: "Brazil", alpha3: "BRA", code: "076" },
  {
    name: "British Indian Ocean Territory",
    alpha3: "IOT",
    code: "086",
  },
  { name: "Brunei Darussalam", alpha3: "BRN", code: "096" },
  { name: "Bulgaria", alpha3: "BGR", code: "100" },
  { name: "Burkina Faso", alpha3: "BFA", code: "854" },
  { name: "Burundi", alpha3: "BDI", code: "108" },
  { name: "Cabo Verde", alpha3: "CPV", code: "132" },
  { name: "Cambodia", alpha3: "KHM", code: "116" },
  { name: "Cameroon", alpha3: "CMR", code: "120" },
  { name: "Canada", alpha3: "CAN", code: "124" },
  { name: "Cayman Islands", alpha3: "CYM", code: "136" },
  { name: "Central African Republic", alpha3: "CAF", code: "140" },
  { name: "Chad", alpha3: "TCD", code: "148" },
  { name: "Chile", alpha3: "CHL", code: "152" },
  { name: "China", alpha3: "CHN", code: "156" },
  { name: "Christmas Island", alpha3: "CXR", code: "162" },
  { name: "Cocos (Keeling) Islands", alpha3: "CCK", code: "166" },
  { name: "Colombia", alpha3: "COL", code: "170" },
  { name: "Comoros", alpha3: "COM", code: "174" },
  { name: "Congo", alpha3: "COG", code: "178" },
  {
    name: "Congo, Democratic Republic of the",
    alpha3: "COD",
    code: "180",
  },
  { name: "Cook Islands", alpha3: "COK", code: "184" },
  { name: "Costa Rica", alpha3: "CRI", code: "188" },
  { name: "Côte d'Ivoire", alpha3: "CIV", code: "384" },
  { name: "Croatia", alpha3: "HRV", code: "191" },
  { name: "Cuba", alpha3: "CUB", code: "192" },
  { name: "Curaçao", alpha3: "CUW", code: "531" },
  { name: "Cyprus", alpha3: "CYP", code: "196" },
  { name: "Czechia", alpha3: "CZE", code: "203" },
  { name: "Denmark", alpha3: "DNK", code: "208" },
  { name: "Djibouti", alpha3: "DJI", code: "262" },
  { name: "Dominica", alpha3: "DMA", code: "212" },
  { name: "Dominican Republic", alpha3: "DOM", code: "214" },
  { name: "Ecuador", alpha3: "ECU", code: "218" },
  { name: "Egypt", alpha3: "EGY", code: "818" },
  { name: "El Salvador", alpha3: "SLV", code: "222" },
  { name: "Equatorial Guinea", alpha3: "GNQ", code: "226" },
  { name: "Eritrea", alpha3: "ERI", code: "232" },
  { name: "Estonia", alpha3: "EST", code: "233" },
  { name: "Eswatini", alpha3: "SWZ", code: "748" },
  { name: "Ethiopia", alpha3: "ETH", code: "231" },
  {
    name: "Falkland Islands (Malvinas)",
    alpha3: "FLK",
    code: "238",
  },
  { name: "Faroe Islands", alpha3: "FRO", code: "234" },
  { name: "Fiji", alpha3: "FJI", code: "242" },
  { name: "Finland", alpha3: "FIN", code: "246" },
  { name: "France", alpha3: "FRA", code: "250" },
  { name: "French Guiana", alpha3: "GUF", code: "254" },
  { name: "French Polynesia", alpha3: "PYF", code: "258" },
  {
    name: "French Southern Territories",
    alpha3: "ATF",
    code: "260",
  },
  { name: "Gabon", alpha3: "GAB", code: "266" },
  { name: "Gambia", alpha3: "GMB", code: "270" },
  { name: "Georgia", alpha3: "GEO", code: "268" },
  { name: "Germany", alpha3: "DEU", code: "276" },
  { name: "Ghana", alpha3: "GHA", code: "288" },
  { name: "Gibraltar", alpha3: "GIB", code: "292" },
  { name: "Greece", alpha3: "GRC", code: "300" },
  { name: "Greenland", alpha3: "GRL", code: "304" },
  { name: "Grenada", alpha3: "GRD", code: "308" },
  { name: "Guadeloupe", alpha3: "GLP", code: "312" },
  { name: "Guam", alpha3: "GUM", code: "316" },
  { name: "Guatemala", alpha3: "GTM", code: "320" },
  { name: "Guernsey", alpha3: "GGY", code: "831" },
  { name: "Guinea", alpha3: "GIN", code: "324" },
  { name: "Guinea-Bissau", alpha3: "GNB", code: "624" },
  { name: "Guyana", alpha3: "GUY", code: "328" },
  { name: "Haiti", alpha3: "HTI", code: "332" },
  {
    name: "Heard Island and McDonald Islands",
    alpha3: "HMD",
    code: "334",
  },
  { name: "Holy See", alpha3: "VAT", code: "336" },
  { name: "Honduras", alpha3: "HND", code: "340" },
  { name: "Hong Kong", alpha3: "HKG", code: "344" },
  { name: "Hungary", alpha3: "HUN", code: "348" },
  { name: "Iceland", alpha3: "ISL", code: "352" },
  { name: "India", alpha3: "IND", code: "356" },
  { name: "Indonesia", alpha3: "IDN", code: "360" },
  {
    name: "Iran (Islamic Republic of)",
    alpha3: "IRN",
    code: "364",
  },
  { name: "Iraq", alpha3: "IRQ", code: "368" },
  { name: "Ireland", alpha3: "IRL", code: "372" },
  { name: "Isle of Man", alpha3: "IMN", code: "833" },
  { name: "Israel", alpha3: "ISR", code: "376" },
  { name: "Italy", alpha3: "ITA", code: "380" },
  { name: "Jamaica", alpha3: "JAM", code: "388" },
  { name: "Japan", alpha3: "JPN", code: "392" },
  { name: "Jersey", alpha3: "JEY", code: "832" },
  { name: "Jordan", alpha3: "JOR", code: "400" },
  { name: "Kazakhstan", alpha3: "KAZ", code: "398" },
  { name: "Kenya", alpha3: "KEN", code: "404" },
  { name: "Kiribati", alpha3: "KIR", code: "296" },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha3: "PRK",
    code: "408",
  },
  { name: "Korea, Republic of", alpha3: "KOR", code: "410" },
  { name: "Kuwait", alpha3: "KWT", code: "414" },
  { name: "Kyrgyzstan", alpha3: "KGZ", code: "417" },
  {
    name: "Lao People's Democratic Republic",
    alpha3: "LAO",
    code: "418",
  },
  { name: "Latvia", alpha3: "LVA", code: "428" },
  { name: "Lebanon", alpha3: "LBN", code: "422" },
  { name: "Lesotho", alpha3: "LSO", code: "426" },
  { name: "Liberia", alpha3: "LBR", code: "430" },
  { name: "Libya", alpha3: "LBY", code: "434" },
  { name: "Liechtenstein", alpha3: "LIE", code: "438" },
  { name: "Lithuania", alpha3: "LTU", code: "440" },
  { name: "Luxembourg", alpha3: "LUX", code: "442" },
  { name: "Macao", alpha3: "MAC", code: "446" },
  { name: "Madagascar", alpha3: "MDG", code: "450" },
  { name: "Malawi", alpha3: "MWI", code: "454" },
  { name: "Malaysia", alpha3: "MYS", code: "458" },
  { name: "Maldives", alpha3: "MDV", code: "462" },
  { name: "Mali", alpha3: "MLI", code: "466" },
  { name: "Malta", alpha3: "MLT", code: "470" },
  { name: "Marshall Islands", alpha3: "MHL", code: "584" },
  { name: "Martinique", alpha3: "MTQ", code: "474" },
  { name: "Mauritania", alpha3: "MRT", code: "478" },
  { name: "Mauritius", alpha3: "MUS", code: "480" },
  { name: "Mayotte", alpha3: "MYT", code: "175" },
  { name: "Mexico", alpha3: "MEX", code: "484" },
  {
    name: "Micronesia (Federated States of)",
    alpha3: "FSM",
    code: "583",
  },
  { name: "Moldova, Republic of", alpha3: "MDA", code: "498" },
  { name: "Monaco", alpha3: "MCO", code: "492" },
  { name: "Mongolia", alpha3: "MNG", code: "496" },
  { name: "Montenegro", alpha3: "MNE", code: "499" },
  { name: "Montserrat", alpha3: "MSR", code: "500" },
  { name: "Morocco", alpha3: "MAR", code: "504" },
  { name: "Mozambique", alpha3: "MOZ", code: "508" },
  { name: "Myanmar", alpha3: "MMR", code: "104" },
  { name: "Namibia", alpha3: "NAM", code: "516" },
  { name: "Nauru", alpha3: "NRU", code: "520" },
  { name: "Nepal", alpha3: "NPL", code: "524" },
  { name: "Netherlands", alpha3: "NLD", code: "528" },
  { name: "New Caledonia", alpha3: "NCL", code: "540" },
  { name: "New Zealand", alpha3: "NZL", code: "554" },
  { name: "Nicaragua", alpha3: "NIC", code: "558" },
  { name: "Niger", alpha3: "NER", code: "562" },
  { name: "Nigeria", alpha3: "NGA", code: "566" },
  { name: "Niue", alpha3: "NIU", code: "570" },
  { name: "Norfolk Island", alpha3: "NFK", code: "574" },
  { name: "North Macedonia", alpha3: "MKD", code: "807" },
  { name: "Northern Mariana Islands", alpha3: "MNP", code: "580" },
  { name: "Norway", alpha3: "NOR", code: "578" },
  { name: "Oman", alpha3: "OMN", code: "512" },
  { name: "Pakistan", alpha3: "PAK", code: "586" },
  { name: "Palau", alpha3: "PLW", code: "585" },
  { name: "Palestine, State of", alpha3: "PSE", code: "275" },
  { name: "Panama", alpha3: "PAN", code: "591" },
  { name: "Papua New Guinea", alpha3: "PNG", code: "598" },
  { name: "Paraguay", alpha3: "PRY", code: "600" },
  { name: "Peru", alpha3: "PER", code: "604" },
  { name: "Philippines", alpha3: "PHL", code: "608" },
  { name: "Pitcairn", alpha3: "PCN", code: "612" },
  { name: "Poland", alpha3: "POL", code: "616" },
  { name: "Portugal", alpha3: "PRT", code: "620" },
  { name: "Puerto Rico", alpha3: "PRI", code: "630" },
  { name: "Qatar", alpha3: "QAT", code: "634" },
  { name: "Réunion", alpha3: "REU", code: "638" },
  { name: "Romania", alpha3: "ROU", code: "642" },
  { name: "Russian Federation", alpha3: "RUS", code: "643" },
  { name: "Rwanda", alpha3: "RWA", code: "646" },
  { name: "Saint Barthélemy", alpha3: "BLM", code: "652" },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha3: "SHN",
    code: "654",
  },
  { name: "Saint Kitts and Nevis", alpha3: "KNA", code: "659" },
  { name: "Saint Lucia", alpha3: "LCA", code: "662" },
  {
    name: "Saint Martin (French part)",
    alpha3: "MAF",
    code: "663",
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha3: "SPM",
    code: "666",
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha3: "VCT",
    code: "670",
  },
  { name: "Samoa", alpha3: "WSM", code: "882" },
  { name: "San Marino", alpha3: "SMR", code: "674" },
  { name: "Sao Tome and Principe", alpha3: "STP", code: "678" },
  { name: "Saudi Arabia", alpha3: "SAU", code: "682" },
  { name: "Senegal", alpha3: "SEN", code: "686" },
  { name: "Serbia", alpha3: "SRB", code: "688" },
  { name: "Seychelles", alpha3: "SYC", code: "690" },
  { name: "Sierra Leone", alpha3: "SLE", code: "694" },
  { name: "Singapore", alpha3: "SGP", code: "702" },
  {
    name: "Sint Maarten (Dutch part)",
    alpha3: "SXM",
    code: "534",
  },
  { name: "Slovakia", alpha3: "SVK", code: "703" },
  { name: "Slovenia", alpha3: "SVN", code: "705" },
  { name: "Solomon Islands", alpha3: "SLB", code: "090" },
  { name: "Somalia", alpha3: "SOM", code: "706" },
  { name: "South Africa", alpha3: "ZAF", code: "710" },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha3: "SGS",
    code: "239",
  },
  { name: "South Sudan", alpha3: "SSD", code: "728" },
  { name: "Spain", alpha3: "ESP", code: "724" },
  { name: "Sri Lanka", alpha3: "LKA", code: "144" },
  { name: "Sudan", alpha3: "SDN", code: "729" },
  { name: "Suriname", alpha3: "SUR", code: "740" },
  { name: "Svalbard and Jan Mayen", alpha3: "SJM", code: "744" },
  { name: "Sweden", alpha3: "SWE", code: "752" },
  { name: "Switzerland", alpha3: "CHE", code: "756" },
  { name: "Syrian Arab Republic", alpha3: "SYR", code: "760" },
  {
    name: "Taiwan, Province of China",
    alpha3: "TWN",
    code: "158",
  },
  { name: "Tajikistan", alpha3: "TJK", code: "762" },
  {
    name: "Tanzania, United Republic of",
    alpha3: "TZA",
    code: "834",
  },
  { name: "Thailand", alpha3: "THA", code: "764" },
  { name: "Timor-Leste", alpha3: "TLS", code: "626" },
  { name: "Togo", alpha3: "TGO", code: "768" },
  { name: "Tokelau", alpha3: "TKL", code: "772" },
  { name: "Tonga", alpha3: "TON", code: "776" },
  { name: "Trinidad and Tobago", alpha3: "TTO", code: "780" },
  { name: "Tunisia", alpha3: "TUN", code: "788" },
  { name: "Turkey", alpha3: "TUR", code: "792" },
  { name: "Turkmenistan", alpha3: "TKM", code: "795" },
  { name: "Turks and Caicos Islands", alpha3: "TCA", code: "796" },
  { name: "Tuvalu", alpha3: "TUV", code: "798" },
  { name: "Uganda", alpha3: "UGA", code: "800" },
  { name: "Ukraine", alpha3: "UKR", code: "804" },
  { name: "United Arab Emirates", alpha3: "ARE", code: "784" },
  {
    name: "United Kingdom",
    alpha3: "GBR",
    code: "826",
  },
  { name: "United States of America", alpha3: "USA", code: "840" },
  {
    name: "United States Minor Outlying Islands",
    alpha3: "UMI",
    code: "581",
  },
  { name: "Uruguay", alpha3: "URY", code: "858" },
  { name: "Uzbekistan", alpha3: "UZB", code: "860" },
  { name: "Vanuatu", alpha3: "VUT", code: "548" },
  {
    name: "Venezuela (Bolivarian Republic of)",
    alpha3: "VEN",
    code: "862",
  },
  { name: "Viet Nam", alpha3: "VNM", code: "704" },
  { name: "Virgin Islands (British)", alpha3: "VGB", code: "092" },
  { name: "Virgin Islands (U.S.)", alpha3: "VIR", code: "850" },
  { name: "Wallis and Futuna", alpha3: "WLF", code: "876" },
  { name: "Western Sahara", alpha3: "ESH", code: "732" },
  { name: "Yemen", alpha3: "YEM", code: "887" },
  { name: "Zambia", alpha3: "ZMB", code: "894" },
  { name: "Zimbabwe", alpha3: "ZWE", code: "716" },
]

export default iso3166Codes
