export default class ApiError extends Error {
  status!: number

  message!: string

  response!: string

  errors?: any

  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
