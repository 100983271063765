import { FunctionComponent } from "react"
import { QueryClientProvider } from "@tanstack/react-query"
import Head from "next/head"
import Breadcrumbs from "./breadcrumbs"
import Footer from "./footer"
import Header from "./header"
import PageHeader from "./page-header"
import queryClient from "@/lib/query-client"

type LayoutProps = {
  title: string
  breadcrumbTitle?: string
  noindex?: boolean
  defaultHeader?: boolean
  breadcrumbs?: {
    url?: string
    title: string
  }[]
}

const Layout: FunctionComponent<React.PropsWithChildren<LayoutProps>> = ({
  children,
  title,
  breadcrumbTitle,
  noindex = false,
  defaultHeader = false,
  breadcrumbs = undefined,
}) => {
  return (
    <div>
      <Head>
        <title>{`${title} | Louvolite`}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {noindex && <meta name="robots" content="noindex" />}
      </Head>

      {/* @fixme duplicated from _app.jsx for persistent layouts in my account area */}
      <QueryClientProvider client={queryClient}>
        <Header />

        <main>
          {defaultHeader && (
            <>
              <PageHeader title={title} />
              <Breadcrumbs current={breadcrumbs || breadcrumbTitle || title} />
            </>
          )}
          {children}
        </main>

        <Footer />
      </QueryClientProvider>
    </div>
  )
}

export default Layout
