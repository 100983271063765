import { useEffect } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Router from "next/router"
import { useAsync, useInterval } from "react-use"
import fetcher from "@/lib/fetcher"

const QUERY_KEY = "AUTH_USER"

export default function useUser({
  redirectTo = "",
  redirectIfFound = false,
} = {}) {
  const queryClient = useQueryClient()

  const { data: user, refetch } = useQuery<
    {
      name: string
      isLoggedIn: boolean
      access_token: string
      expires_at: number
    },
    Error
  >({ queryKey: [QUERY_KEY], queryFn: () => fetcher("/api/user") })

  const refreshToken = async () => {
    if (user && user.isLoggedIn && user.expires_at) {
      const expirationTime = new Date(user.expires_at * 1000).getTime()
      const currentTime = Date.now()
      const timeUntilExpiration = expirationTime - currentTime

      if (timeUntilExpiration <= 0) {
        await refetch()
      }
    }
  }

  useInterval(async () => {
    await refreshToken()
  }, 15 * 1000)

  useAsync(async () => {
    await refreshToken()
  }, [user])

  const { mutateAsync: login } = useMutation({
    mutationFn: (credentials: { login: string; password: string }) =>
      fetcher("/api/login", "POST", credentials),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] })
    },
  })

  const { mutateAsync: logout } = useMutation({
    mutationFn: () => fetcher("/api/logout", "POST"),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY] })
    },
  })

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || !user) return

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user?.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user?.isLoggedIn)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Router.push(redirectTo)
    }
  }, [user, redirectIfFound, redirectTo])

  return { user, login, logout }
}
