import { format, parseISO } from "date-fns"
import { startCase, toLower } from "lodash"
import iso3166Codes from "@/lib/iso-3166-alpha3"
import { UserAddress } from "@/types"

export function nl2br(str: string, isXhtml = true) {
  if (str === undefined || str === null) {
    return ""
  }

  const breakTag = isXhtml || isXhtml ? "<br />" : "<br>"

  // eslint-disable-next-line unicorn/prefer-string-replace-all
  return `${str}`.replace(/(\r\n|\n\r|\r|\n)/g, `${breakTag}$1`)
}

export const telAttribute = (value?: string) => {
  if (!value) {
    return ""
  }

  return `tel:${value?.replaceAll(/\D/g, "")}`
}

export const readableDate = (value: string) =>
  format(parseISO(value), "dd/MM/yyyy")

export const titleCase = (value: string) => startCase(toLower(value))

export const isoToCountry = (value: string) =>
  iso3166Codes.find((code) => code.alpha3 === value)?.name || value

export const readableAddress = (address: UserAddress, delimiter = ", ") => {
  let output = ""
  const street = [
    titleCase(address.building_compliment),
    titleCase(`${address.street} ${address.street_number}`),
    titleCase(address.city),
    address.postcode,
  ]
    .map((item) => item.trim())
    .filter((item) => item.length > 0)
    .join(delimiter)

  if (address.name) {
    output += `${address.name}<br/>`
  }

  if (street) {
    output += `${street}<br/>`
  }

  if (address.country_region_id) {
    output += `${isoToCountry(address.country_region_id)}`
  }

  return output
}
