import React from "react"
import Link from "next/link"
import S from "./breadcrumbs.module.scss"

type BreadcrumbItem = { url?: string; title: string }

type Props = {
  current: string | BreadcrumbItem[]
}

const Breadcrumbs: React.FC<Props> = ({ current }) => {
  let data: BreadcrumbItem[] = [
    {
      url: "/",
      title: "Home",
    },
  ]

  data = Array.isArray(current)
    ? [...data, ...current]
    : [...data, { title: current }]

  return (
    <nav aria-label="breadcrumb" className={S.wrap}>
      <div className="container">
        <ol className="breadcrumb">
          {data.map((item) => {
            if (item.url) {
              return (
                <li
                  className="breadcrumb-item"
                  aria-current="page"
                  key={item.title}
                >
                  <Link href={item.url}>{item.title}</Link>
                </li>
              )
            }
            return (
              <li
                className="breadcrumb-item active"
                aria-current="page"
                key={item.title}
              >
                {item.title}
              </li>
            )
          })}
        </ol>
      </div>
    </nav>
  )
}

export default Breadcrumbs
