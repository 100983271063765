import React, { Children } from "react"
import Link, { LinkProps } from "next/link"
import { useRouter } from "next/router"

type Props = {
  activeClassName: string
  strict?: boolean
}

const ActiveLink: React.FC<
  React.PropsWithChildren<Props> & LinkProps & { href: string }
> = ({ href, children, activeClassName, strict = false, ...props }) => {
  const { asPath, pathname } = useRouter()
  const child = Children.only(children)
  // @ts-ignore
  const childClassName = String(child.props.className || "")
  const match = strict ? asPath === href : pathname.includes(href)
  const className = match
    ? `${childClassName} ${activeClassName}`.trim()
    : childClassName

  return (
    <Link href={href} {...props} legacyBehavior>
      {/* @ts-ignore */}
      {React.cloneElement(child, {
        className: className || null,
      })}
    </Link>
  )
}

ActiveLink.propTypes = {}

export default ActiveLink
