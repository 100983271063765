import { FunctionComponent, useRef } from "react"
import S from "./page-header.module.scss"
import ArrowDown from "../svgs/feather-arrow-down.svg"

type PageHeaderProps = {
  title: string
  scrollButton?: boolean
}

const PageHeader: FunctionComponent<PageHeaderProps> = ({
  title,
  scrollButton = true,
}) => {
  const sectionEl = useRef<HTMLElement>(null)

  const handleScroll = () => {
    if (sectionEl.current && sectionEl.current.nextSibling) {
      const nextSection = sectionEl.current.nextSibling as HTMLElement
      nextSection.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <section className={S.wrapper} ref={sectionEl}>
      <div className="container">
        <h1 className={S.title}>{title}</h1>

        {scrollButton && (
          <button
            type="button"
            className={S.scrollButton}
            onClick={handleScroll}
            aria-label="Scroll down"
          >
            <ArrowDown />
          </button>
        )}
      </div>
    </section>
  )
}

export default PageHeader
