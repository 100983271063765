import clsx from "clsx"
import Link from "next/link"
import S from "./footer.module.scss"
import { siteConfig } from "../lib/globals"
import { telAttribute } from "../lib/utils"
import MailIcon from "../svgs/mail.svg"
import PhoneIcon from "../svgs/phone.svg"
import RadicalLogo from "../svgs/radical.svg"
import FacebookIcon from "../svgs/social-facebook.svg"
import InstagramIcon from "../svgs/social-instagram.svg"
import PinterestIcon from "../svgs/social-pinterest.svg"
import TwitterIcon from "../svgs/social-twitter.svg"
import YouTubeIcon from "../svgs/social-youtube.svg"

export default function Footer() {
  return (
    <footer className={S.wrapper}>
      <div className={S.top}>
        <div className="container">
          <div className={S.socials}>
            <a
              href="https://www.facebook.com/Louvolite/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/louvolite/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.pinterest.co.uk/louvolite/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Pinterest"
            >
              <PinterestIcon />
            </a>
            <a
              href="https://twitter.com/louvolite/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.youtube.com/user/LouvoliteBlinds"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <YouTubeIcon />
            </a>
          </div>
          <div className="row">
            <div className="col-lg col-xl-3">
              <p className={S.title}>Louvolite</p>

              <div className="mb-3 mb-lg-4">
                <p>
                  Ashton Road, Hyde,
                  <br className="d-none d-lg-block" />
                  <span className="d-lg-none">&nbsp;</span>
                  Cheshire, SK14 4BG
                </p>
              </div>

              <p className={S.contact}>
                <a href={telAttribute(siteConfig.phone)}>
                  <PhoneIcon />
                  {siteConfig.phone}
                </a>
              </p>

              <p className={S.contact}>
                <a href={`mailto:${siteConfig.email}`}>
                  <MailIcon />
                  {siteConfig.email}
                </a>
              </p>
            </div>

            <div className="col-lg col-xl-3 mt-4 mt-lg-0">
              <p className={S.title}>Opening times</p>

              <div className="row">
                <div className={clsx(S.days, "col-6")}>
                  <p>Monday</p>
                  <p>Tuesday</p>
                  <p>Wednesday</p>
                  <p>Thursday</p>
                  <p>Friday</p>
                </div>
                <div className={clsx(S.hours, "col-6 text-right")}>
                  <p>9AM - 5PM</p>
                  <p>9AM - 5PM</p>
                  <p>9AM - 5PM</p>
                  <p>9AM - 5PM</p>
                  <p>9AM - 3:30PM</p>
                </div>
              </div>
            </div>

            <div className={clsx(S.separator, "col-lg-1")} />

            <div className="col-lg col-xl-4 pl-xl-5 mt-4 mt-lg-0">
              <p className={S.title}>Customer Services</p>

              <div className={clsx("row justify-content-between", S.links)}>
                <div className="col-6 col-lg-auto">
                  <p>
                    <Link href="/contact">Contact Us</Link>
                  </p>
                  <p>
                    <Link href="/faq">FAQ</Link>
                  </p>
                </div>
                <div className="col-6 col-lg-auto">
                  <p>
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </p>
                  <p>
                    <Link href="/terms-and-conditions">Terms & Conditions</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={S.bottom}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="d-lg-flex justify-content-between">
                <li>
                  <a
                    href="/files/The-Modern-Slavery-Act-Certificate.pdf"
                    target="_blank"
                  >
                    The Modern Slavery Act of 2015
                  </a>
                </li>
                <li>
                  <a href="/files/Gender-Pay-2021.pdf" target="_blank">
                    Equality Act 2010
                  </a>
                </li>
                <li>
                  <Link href="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link href="/terms-and-conditions">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 text-lg-right mt-2 mt-lg-0">
              <p>
                Louvolite. All rights reserved.&nbsp;
                {new Date().getFullYear()}
              </p>
            </div>
          </div>

          <div className="row mt-3 mt-lg-2">
            <div className="col-lg-8">
              <p>
                Louvolite is the trading name of Louver-Lite registered in
                England No. 1011431
              </p>
            </div>
            <div className="col-lg-4 text-lg-right mt-2 mt-lg-0">
              <p className="d-inline-flex align-items-center">
                Website by
                <a
                  href="//radicalwebdesign.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Radical Web Design"
                >
                  <RadicalLogo className="ml-2" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
